<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Data</b-card-title>
        <b-card-title class="text-primary">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            size="sm"
            :to="{ name: 'mstPemeriksaanSediaanTambah' }"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col cols="6" offset-md="3">
            <h4>Filter</h4>
            <b-form class="needs-validation" @submit.prevent="submitFilter">
              <b-col offset-md="1" cols="10">
                <b-form-group label="Layanan" label-for="layanan">
                  <b-form-select
                    v-model="layananFilter"
                    id="layanan"
                    :options="dataLayanan"
                    @change="changeLayanan"
                  />
                </b-form-group>

                <b-form-group
                  label="Jenis Pemeriksaan"
                  label-for="jenisPemeriksaan"
                  v-if="isShowJenisPemeriksaan"
                >
                  <b-form-select
                    v-model="jenisPemeriksaanFilter"
                    id="jenisPemeriksaan"
                    :options="dataJenisPemeriksaan"
                  />
                </b-form-group>
              </b-col>
              <b-col offset-md="9" cols="2" align="right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  type="submit"
                >
                  Filter
                </b-button>
              </b-col>
            </b-form>
          </b-col>
          <b-col cols="12">
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Cari</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Cari"
                    type="text"
                    class="d-inline-block"
                    @input="handleSearch"
                  />
                </div>
              </b-form-group>
            </div>
            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: row,
                jumpFirstOrLast: true,
              }"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              mode="remote"
              @on-sort-change="onSortChange"
              :group-options="{
                enabled: true,
                collapsable: true, // or column index
                headerPosition: 'top',
              }"
            >
              <template slot="table-header-row" slot-scope="props">
                <span v-if="props.column.field == 'aksi'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        :to="{
                          name: 'mstPemeriksaanSediaanUbah',
                          params: { id: props.row.id },
                        }"
                      >
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Ubah</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteButton(props.row.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Common -->
                <span>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Menampilkan 1 hingga </span>
                    <b-form-select
                      v-model="row"
                      :options="['10', '100', '1000']"
                      class="mx-1"
                      @input="handlePageChange"
                    />
                    <span class="text-nowrap">
                      dari {{ totalRecords }} entri
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="totalRecords"
                      :per-page="row"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @change="handleChangePage"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BPagination,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BForm,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";

export default {
  setup(props, context) {
    const g = context.root;
    const $http = g.$http;

    let rows = ref([]);
    let searchTerm = ref("");
    let start = ref(1);
    let row = ref(10);
    let totalRecords = ref(0);
    let sortColumn = ref("");
    let order = ref("");
    let isLoading = ref(false);
    let dataJenisPemeriksaan = ref([]);
    let isShowJenisPemeriksaan = ref(false);

    let layananFilter = ref(g.$route.query.layanan || "");
    let jenisPemeriksaanFilter = ref(g.$route.query.jenisPemeriksaan || "");

    if (!g.$route.query.layanan) jenisPemeriksaanFilter.value = "";

    const datatable = () => {
      isLoading.value = true;
      $http({
        url: "/pemeriksaan/sediaan/get-data",
        params: {
          start: start.value - 1,
          row: row.value,
          search: searchTerm.value,
          sortColumn: sortColumn.value,
          order: order.value,
          layananFilter: layananFilter.value,
          jenisPemeriksaanFilter: jenisPemeriksaanFilter.value,
        },
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          isLoading.value = false;
          rows.value = res.data.data;
          totalRecords.value = res.data.totalRecord;
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Load Table`,
            variant: "danger",
            solid: true,
          });
        });
    };
    datatable();

    const submitFilter = () => {
      let url =
        "?layanan=" +
        layananFilter.value +
        `&jenisPemeriksaan=${jenisPemeriksaanFilter.value}`;
      window.history.pushState("", "", url);
      start.value = 1;
      datatable();
    };

    const changeLayanan = () => {
      if (!layananFilter.value) jenisPemeriksaanFilter.value = "";
      $http({
        url: "/pemeriksaan/jenisPemeriksaan/get-data-jenis-pemeriksaan",
        params: {
          layanan: layananFilter.value,
          jenisPemeriksaan: jenisPemeriksaanFilter.value,
        },
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          const { dataJenisPemeriksaanVal, jenisPemeriksaanVal } =
            res.data.data;
          dataJenisPemeriksaan.value = dataJenisPemeriksaanVal;
          jenisPemeriksaanFilter.value = jenisPemeriksaanVal;

          isShowJenisPemeriksaan.value = layananFilter.value ? true : false;
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Load Table`,
            variant: "danger",
            solid: true,
          });
        });
    };

    if (g.$route.query.jenisPemeriksaan) {
      isShowJenisPemeriksaan.value = true;
      changeLayanan();
    }

    const deleteButton = (id) => {
      g.$swal({
        title: "Apakah Anda Yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          $http({
            url: "/pemeriksaan/sediaan/" + id,
            method: "delete",
            headers: {
              Authorization: "Bearer " + g.$cookies.get("token"),
            },
          })
            .then(() => {
              datatable();
            })
            .catch((err) => {
              let showError = err.response
                ? err.response.data
                : err.request
                ? err.request
                : "Error" + err.message;

              g.$bvToast.toast(showError || "Something Wrong", {
                title: `Error Load Table`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    };

    const handleSearch = (searching) => {
      start.value = 1;
      datatable();
    };

    const handlePageChange = (active) => {
      start.value = 1;
      datatable();
    };

    const handleChangePage = (page) => {
      start.value = (page - 1) * row.value + 1;
      datatable();
    };

    const onSortChange = (params) => {
      sortColumn.value = params[0].field;
      order.value = params[0].type;
      datatable();
    };

    return {
      submitFilter,
      datatable,
      handleSearch,
      handlePageChange,
      handleChangePage,
      onSortChange,
      deleteButton,
      changeLayanan,
      layananFilter,
      rows,
      searchTerm,
      start,
      row,
      totalRecords,
      sortColumn,
      order,
      isLoading,
      jenisPemeriksaanFilter,
      isShowJenisPemeriksaan,
      dataJenisPemeriksaan,
    };
  },
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BForm,
  },
  data() {
    return {
      columns: [
        {
          label: "No",
          field: "no",
          width: "7%",
        },
        {
          label: "Label",
          field: "label",
        },
        {
          label: "Layanan",
          field: "layanan",
        },
        {
          label: "Jenis Pemeriksaan",
          field: "jenisPemeriksaan",
        },
        {
          label: "Aksi",
          field: "aksi",
          width: "1%",
          sortable: false,
        },
      ],
      dataLayanan: [],
    };
  },
  directives: {
    Ripple,
  },
  created() {
    this.$http({
      url: "/pemeriksaan/jenisPemeriksaan",
      params: {
        layanan: this.layananFilter,
      },
      headers: {
        Authorization: "Bearer " + $cookies.get("token"),
      },
    })
      .then((res) => {
        const d = res.data.data;
        this.layananFilter = d.layananVal;
        this.dataLayanan = d.dataLayanan;
      })
      .catch((err) => {
        let showError = err.response
          ? err.response.data
          : err.request
          ? err.request
          : "Error" + err.message;

        this.$bvToast.toast(showError || "Something Wrong", {
          title: `Error Load Table`,
          variant: "danger",
          solid: true,
        });
      });
  },
};
</script>

<style lang="scss" scope>
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>